import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { extend } from '@react-three/fiber';
import * as THREE from 'three';

extend({ TextGeometry });

const SpinningText = ({ text }) => {
  const meshRef = useRef();
  const [font] = useState(new FontLoader().parse(require('./fonts/Gladifilthefte.json')));

  useFrame(() => {
    meshRef.current.rotation.y += 0.001;
  });

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.center(); // Center the geometry
    }
  }, [text]); // This will re-center the text when it changes

  const textOptions = {
    font,
    size: 1,
    height: 0.1, // Increased depth for more dramatic shadows
    curveSegments: 16,
    bevelEnabled: true,
    bevelThickness: 0.05,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5
  };

  return (
    <mesh
      ref={meshRef}
      castShadow // This object will cast shadows
      receiveShadow // This object can receive shadows
    >
      <textGeometry attach="geometry" args={[text, textOptions]} />
      <meshStandardMaterial attach="material" color="#F3F3F3" side={THREE.DoubleSide} />
    </mesh>
  );
};

export default SpinningText;
