import React, { useState, useEffect, useCallback, useMemo  } from 'react';
import { Canvas } from '@react-three/fiber';
import SpinningText from './SpinningText';
import './App.css';

function App() {
  const [message, setMessage] = useState('');

  const absurdities = useMemo(() => [
    "BRUH",
    "I'm not a rapper",
    "w t f",
    "sexy",
  ], []);

  // Use `useCallback` to memoize the function so it only changes if absurdities changes
  const generateAbsurdity = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * absurdities.length);
    setMessage(absurdities[randomIndex]);
  }, [absurdities]);

  useEffect(() => {
    generateAbsurdity();
  }, [generateAbsurdity]); // Now `generateAbsurdity` is a dependency

  return (
    <div className="App">
      <header className="App-header">
        <Canvas
          shadows
          camera={{ position: [0, 0, 10], fov: 40 }}
        >
          <ambientLight intensity={0.1} />
          <spotLight
            position={[0, 5, 10]}
            angle={0.3}
            penumbra={1}
            intensity={550}
            castShadow
          />
          <SpinningText text={message} />
        </Canvas>
        <button onClick={generateAbsurdity}>
          What else?
        </button>
      </header>
    </div>
  );
}

export default App;
